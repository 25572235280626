import React, {  } from 'react';
import SiteLayoutEmbeded from '../../components/SiteLayoutEmbeded';
import { Location } from '@reach/router';

import { AksepterComponent } from '../aksepter';

function Index() {
    return (
        <Location>
            {({ location }) => (
                <SiteLayoutEmbeded>
                    <AksepterComponent location={location} isEmbeded={true} />
                </SiteLayoutEmbeded>
            )}
        </Location>
    );
}

export default Index;
